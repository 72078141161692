.main {
  overflow-y: auto; /* Enable scrolling */
  position: relative; /* Corrected position property */
  height: "100%"; /* Ensure it takes the full viewport height */
  width: "100%";
  max-width: 640px;
  padding: 0;
}

.scrollable-content {
  padding: 20px 10px;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.reservation-container {
  padding: 20px;
}

.main-button {
  padding: 10px 20px;
  margin: 0px;
  background-color: #52575a;
  color: black;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.menu-display {
  margin-top: 20px;
}

.menu-item {
  padding: 10px 20px;
  margin: 10px;
  background-color: #94999c;
  color: black;
  width: 100%;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  justify-content: space-evenly;
}

.menu-title {
  font-weight: bold;
  color: #cfd6cb;
}

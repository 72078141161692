.cart-popup {
  /* position: "fixed"; */
  top: 120px;
  width: 100%;
  /* height: 100%; */
  /* max-width: 750px; */
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-item button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.cart-item button:hover {
  background-color: #ff1a1a;
}

.cart-total {
  margin-top: 20px;
  text-align: right;
}

.reservation-section {
  margin-top: 20px;
}

.time-slots {
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
}

.time-slot {
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.time-slot.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

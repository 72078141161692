.home-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 750px;
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  overflow-x: hidden;
}

.main-container {
  margin-top: 60px; /* to offset the fixed header */
}

.hero-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  padding-top: 20px;
}

.logo-text {
  font-size: 48px;
  letter-spacing: 5px;
  font-weight: bold;
  color: black;
}

.sub-text {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: black;
}
.button-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.cta-button {
  padding: 10px 20px;
  background-color: #a33adb;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}

.grand-opening-section {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  background-color: #eadef0;
}

.grand-opening-image {
  width: 100%;
  max-width: 600px;
  height: auto;
}

.text-content {
  margin-top: 20px;
  padding: 20px;
}

.text-content h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.text-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  text-align: left;
  margin: 0 auto;
}

.contents-container {
  display: flex;
  flex-direction: "column";
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  max-width: 750px;
}

.content-box {
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: calc(100%); /* 3개씩 배치되도록 설정 */
  box-sizing: border-box;
  flex-direction: row;
}

.content-box h4 {
  margin-top: 0;
}

.content-box p {
  margin-bottom: 0;
}
.container {
  background-color: "white";
  margin-top: 30px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.notice-list {
  list-style-type: none;
  padding: 0;
}

.notice-item {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
  color: #a4abae3e;
  margin-right: 10px;
  font-size: 24px;
}

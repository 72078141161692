@font-face {
  font-family: "Pretendard";
  src: url("../public/font/PretendardVariable.woff2") format("woff2");
  src: url("../public/font/PretendardVariable.ttf") format("truetype");
}

body {
  font-family: "Pretendard", sans-serif;
  background-color: #f7f7f7;
  overflow-y: scroll;
  overflow-x: hidden;
}

.App {
  width: 100%;
  background-color: #d1d1d1;
  background-color: #a8d58e;
}
